<template>
  <div class="container">
    <div :class="classes.wrapper">
      <div :class="classes.title">
        <div :class="classes.titleText">
          Продай свой телефон,<br>
          и купи ещё дешевле
        </div>
      </div>
      <div :class="classes.devices">
        <div :class="classes.device">
          <picture :class="classes.devicePicture">
            <source
              type="image/webp"
              srcset="/static/images/trade-in-section/user-sell-device-mobile.webp"
              width="70"
              height="95"
            >
            <source
              type="image/png"
              srcset="/static/images/trade-in-section/user-sell-device-mobile.png"
              width="70"
              height="95"
            >
            <img
              class="w-full"
              src="/static/images/trade-in-section/user-sell-device-mobile.png"
              alt="iPhone 12, 256 GB"
              width="70"
              height="95"
            >
          </picture>
          <div :class="classes.deviceInfo">
            <div :class="classes.deviceInfoName">
              Выкупим ваш <br>iPhone 12, 256 GB
            </div>
            <div :class="classes.deviceInfoPrice">
              <Icon name="common:money" size="15" />
              27 360 ₽
            </div>
            <BaseButton
              intent="link"
              underline="on"
              :class="classes.deviceInfoButton"
            >
              Хочу продать
            </BaseButton>
          </div>
        </div>
        <div :class="classes.devicesGrid">
          <div v-for="index in 4" :key="index" :class="classes.devicesGridItem">
            <picture
              :class="[
                classes.devicesGridItemImg,
                index === 2 ? classes.devicesGridItemImgActive : '',
              ]"
            >
              <source
                type="image/webp"
                :srcset="`/static/images/trade-in-section/device-${index}.webp`"
                width="65"
                height="89"
              >
              <source
                type="image/png"
                :srcset="`/static/images/trade-in-block/device-${index}.png`"
                width="65"
                height="89"
              >
              <img
                :src="`/static/images/trade-in-block/device-${index}.png`"
                :alt="`iPhone 1${index}`"
                width="65"
                height="89"
              >
            </picture>
            <div>
              {{ `iPhone 1${index}` }}
            </div>
          </div>
        </div>
      </div>
      <div :class="classes.buttonWrapper">
        <BaseButton
          type="nuxt-link"
          intent="base-primary"
          size="middle"
          :url="{ name: ROUTE_NAMES.TRADE_IN }"
          :class="[
            classes.button,
            resetAStyles,
            stretchedLinkStyles,
            '!static',
          ]"
          @click="emitYandexMetrika(YANDEX_METRIKA_GOALS.BANNER0)"
        >
          Онлайн-калькулятор
        </BaseButton>
      </div>
      <div :class="classes.benefits">
        <div
          v-for="(benefitsItem, index) in benefits"
          :key="`${benefitsItem}_${index}`"
          :class="classes.benefitsItem"
        >
          {{ benefitsItem }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { shallowRef } from 'vue'
import { ROUTE_NAMES } from '@/constants'
import { resetAStyles, stretchedLinkStyles, emitYandexMetrika, YANDEX_METRIKA_GOALS } from '@/utils'

const classes = {
  wrapper: cx([
    'relative',
    'grid',
    `[grid-template-areas:'title''devices''button''benefits']`,
    'gap-6',
    'p-6',
    'rounded-[18px]',
    'bg-gradient-trade-in-section',
    'text-primary-on-color',
    'cursor-pointer',
    `xl:[grid-template-areas:'title_devices''benefits_devices''button_devices']`,
    'xl:gap-x-7',
    'xl:gap-y-4',
    'xl:grid-rows-trade-in-section-lg',
    'xl:h-[233px]',
    'xl:overflow-hidden',
    'xl:py-[30px]',
    'xl:px-[56px]',
  ]),
  title: cx(['[grid-area:title]', 'text-center', 'xl:text-left']),
  titleText: cx(['text-[21px]/[120%]', 'xl:text-[36px]/[110%]', 'font-bold']),
  devices: cx([
    'grid',
    '[grid-area:devices]',
    'grid-rows-[repeat(2,auto)]',
    'gap-[25px]',
    'xl:grid-rows-none',
    'xl:grid-cols-[repeat(2,auto)]',
    'xl:gap-[46px]',
    'xl:items-center',
  ]),
  device: cx([
    'grid',
    'grid-cols-[auto_1fr]',
    'gap-[20px]',
    'my-0',
    'mx-auto',
    'xl:gap-6',
    'xl:m-0',
  ]),
  devicePicture: cx(['w-[70px]', 'xl:w-[86px]', 'h-[auto]']),
  deviceInfo: cx([
    'grid',
    'grid-rows-[repeat(3,_auto)]',
    'gap-2',
    'leading-[120%]',
    'xl:items-start',
    'xl:grid-rows-[auto_auto_1fr]',
    'xl:gap-[11px]',
  ]),
  deviceInfoName: cx([
    'text-sm/[21px]',
    'xl:text-base/[120%]',
    'xl:leading-[130%]',
  ]),
  deviceInfoPrice: cx([
    'grid',
    'gap-1.5',
    'grid-cols-[auto_1fr]',
    'items-center',
    'text-[21px]',
    'xl:text-[24px]',
    'font-bold',
    'xl:leading-[130%]',
  ]),
  deviceInfoButton: cx([
    '!justify-start',
    '!text-sm/[21px]',
    '!text-yellow',
    'xl:!text-base/[120%]',
    'xl:!leading-[130%]',
  ]),
  buttonWrapper: cx(['[grid-area:button]', 'xl:my-0', 'xl:mx-auto', 'xl:m-0']),
  button: cx([
    'py-[17px]',
    'px-5',
    'font-bold',
    'xl:py-[13px]',
    'xl:px-[33px]',
    'xl:max-w-[226px]',
  ]),
  devicesGrid: cx([
    'grid',
    'grid-cols-[repeat(4,_auto)]',
    'grid-rows-[1fr]',
    'justify-between',
    'xl:gap-x-[27px]',
    'xl:justify-center',
    'xl:gap-x-[46px]',
  ]),
  devicesGridItem: cx([
    'flex',
    'flex-col',
    'justify-end',
    'items-center',
    'text-[10px]/[12px]',
    'xl:text-[16px]/[120%]',
  ]),
  devicesGridItemImg: cx(['w-[47px]', 'mb-2', 'xl:w-[68px]', 'xl:mb-1.5']),
  devicesGridItemImgActive: cx([
    'box-content',
    'p-1',
    'rounded-[7px]',
    'border-[0.5px]',
    'border-solid',
    'border-white',
    'xl:p-0',
    'xl:border-none',
  ]),
  benefits: cx([
    '[grid-area:benefits]',
    'grid',
    'grid-cols-[repeat(3,_auto)]',
    'grid-rows-[1fr]',
    'gap-x-1',
    'justify-between',
    'text-[14px]/[120%]',
    'font-bold',
    'xl:justify-center',
    'xl:gap-x-6',
    'xl:justify-start',
  ]),
  benefitsItem: cx([
    'relative',
    'pl-2.5',
    'before:absolute',
    'before:top-[7px]',
    'before:left-[-8px]',
    'before:block',
    'before:w-[17px]',
    'before:rotate-90',
    'before:border',
    'before:border-solid',
    'before:border-yellow-yellow',
    'before:rounded-xl',
  ]),
}

const benefits = shallowRef(['За 15 минут', 'Без торга', 'Деньги сразу'])
</script>